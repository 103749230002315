<template>
  <div>
    <LoginModal ref="loginModal" @close="LoginLoadingClose" @loginSuccess="$router.push('/')"></LoginModal>
    <iframe :src="gameLink" class="iframe" frameborder="0" v-if="gameLink"></iframe>
    <div class="none" v-else>404</div>
  </div>
</template>

<script>
import LoginModal from "@/components/LoginModal";
export default {
  components:{
    LoginModal
  },
  data(){
    return {
      timer:null
    }
  },
  computed:{
    gameLink(){
      const str = `demo_game${this.$route.params.id}`
      return this.$t(str) == str ? "" : this.$t(str)
    }
  },
  methods:{
    start(){
      this.timer = setInterval(()=>{
        this.showLogin()
      },30000)
    },
    end(){
      this.timer && clearInterval(this.timer)
    },
    showLogin(){
      this.$refs.loginModal.show(2);
      this.end()
    },
    LoginLoadingClose(){
      this.start()
    }
  },
  mounted(){
    this.start()
  },
  beforeDestroy(){
    this.end()
  },  
}
</script>

<style scoped>
.iframe{
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.none{
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>